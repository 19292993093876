exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-deep-dish-pizza-crust-js": () => import("./../../../src/pages/deep-dish-pizza-crust.js" /* webpackChunkName: "component---src-pages-deep-dish-pizza-crust-js" */),
  "component---src-pages-dns-tricks-js": () => import("./../../../src/pages/dns-tricks.js" /* webpackChunkName: "component---src-pages-dns-tricks-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kathy-tomato-sauce-js": () => import("./../../../src/pages/kathy-tomato-sauce.js" /* webpackChunkName: "component---src-pages-kathy-tomato-sauce-js" */),
  "component---src-pages-pizza-crust-js": () => import("./../../../src/pages/pizza-crust.js" /* webpackChunkName: "component---src-pages-pizza-crust-js" */),
  "component---src-pages-tech-stuff-js": () => import("./../../../src/pages/tech-stuff.js" /* webpackChunkName: "component---src-pages-tech-stuff-js" */)
}

